@use "@pareto-engineering/bem";

$default-margin: 1rem;
$default-padding: 1rem;
$default-box-shadow: 0 .25rem .75rem var(--ui-lines);
$default-text-font-size: calc(var(--s-1) * 1rem);
$default-border-radius: .25rem;
$default-legend-gap: .625rem;
$default-legend-padding: calc($default-padding * .125) calc($default-padding * .625);
$default-legend-line-width: 1.25rem;
$default-legend-line-height: .125rem;
$default-legend-line-margin-right: .3125rem;
$default-border-line-width: .0625rem;

.#{bem.$base} {
  &.area-chart {
    background-color: var(--background-far);
    border-radius: var(--theme-default-border-radius);
    box-shadow: $default-box-shadow;
    margin: $default-margin 0;
    padding: $default-padding;

    h3 {
      color: var(--subtitle);
      margin: calc($default-margin / 5);
      text-align: left;
    }

    .custom-legend {
      display: flex;
      gap: $default-legend-gap;
      justify-content: flex-end;
      padding-bottom: $default-padding;
      padding-right: calc($default-padding * .25);

      .item {
        align-items: center;
        border: $default-border-line-width solid var(--ui-lines);
        border-radius: $default-border-radius;
        display: flex;
        padding: $default-legend-padding;
      }

      .line {
        display: inline-block;
        height: $default-legend-line-height;
        margin-right: $default-legend-line-margin-right;
        width: $default-legend-line-width;
      }

      .text {
        color: var(--paragraph);
        font-size: calc($default-text-font-size * .75);
      }
    }

    .custom-tooltip {
      background-color: var(--background-far);
      border: $default-border-line-width solid var(--ui-lines);
      border-radius: $default-border-radius;
      padding: calc($default-padding * .25);

      .label {
        color: var(--hard-paragraph);
        font-size: $default-text-font-size;
        margin: calc($default-margin * .25);
      }
    }

    /* stylelint-disable selector-max-compound-selectors -- nested elements */
    .recharts-wrapper {
      .recharts-surface {
        .recharts-cartesian-grid line {
          stroke: var(--ui-lines);
        }

        .recharts-text {
          fill: var(--soft-paragraph);
          font-size: calc($default-text-font-size * .75);
        }

        .recharts-text.recharts-label {
          fill: var(--paragraph);
          font-size: $default-text-font-size;
        }
      }
    }
  }
}
