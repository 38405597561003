/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

.#{bem.$base}.debuger {
  .debuger-content {
    font-size: calc(var(--s-1) * 1em);
    white-space: pre;
  }
}


