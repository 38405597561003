/* @pareto-engineering/generator-front 0.2.4 */

@use "@pareto-engineering/bem";

$default-padding: .75em 1.75em;
$compact-padding: .5em .75em;
$default-border-radius: 3em;
$default-color:primary;
$font-weight:bold;
$default-margin: .5em;
$default-animation-time: .31s;

.#{bem.$base}.button {
  background: var(--x, var(--#{$default-color}));
  border: transparent;
  border-radius: $default-border-radius;
  color: var(--on-x, var(--on-#{$default-color}));
  display: inline-flex;
  font-family: "paragraph", sans-serif;
  font-weight: 400;
  justify-content: space-between;
  padding: $default-padding;
  transition: all .25s;
  white-space: nowrap;//var(--theme-default-paragraph);

  &.arrow-right {
    &::after {
      align-self: center;
      content: "-->";
      margin-left: $default-margin;
      vertical-align: middle;
    }

    &:hover:not(:disabled) {
      &::after {
        --final-position: 50%;
        animation: animateArrow $default-animation-time forwards;
      }
    }
  }

  &.arrow-left {
    &::before {
      align-self: center;
      content: "<--";
      margin-right: $default-margin;
      vertical-align: middle;
    }

    &:hover:not(:disabled) {
      &::before {
        --final-position: -50%;
        animation: animateArrow $default-animation-time forwards;
      }
    }
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover
    :not(.#{bem.$modifier-gradient})
    :not(.#{bem.$modifier-ghost})
    :not(.#{bem.$modifier-simple}) {
      background: var(--soft-x, var(--soft-#{$default-color}));
    }

    &:focus {
      background: var(--hard-x, var(--hard-#{$default-color}));
    }
  }

  &.#{bem.$modifier-gradient} {
    background-image: linear-gradient(
      to right,
      var(--hard-x) 30%,
      var(--x) 50%,
      var(--hard-x) 100%
    );
    background-position: left;
    background-size: 200% auto;

    &:not(:disabled):hover {
      background-position: right center;
    }
  }

  &.#{bem.$modifier-compact} {
    padding: $compact-padding;
  }

  &.#{bem.$modifier-ghost} {
    background: transparent;
    border: 1px solid var(--x, var(--#{$default-color}));
    color: var(--y, var(--x, var(--#{$default-color})));

    &:hover,
    &:focus,
    &:disabled {
      background: transparent;
    }

    &:not(:disabled) {
      &:hover {
        border: 1px solid var(--soft-x, var(--soft-#{$default-color}));
        color: var(--soft-y, var(--soft-x, var(--soft-#{$default-color})));
      }

      &:focus {
        border: 1px solid var(--hard-x, var(--hard-#{$default-color}));
        color: var(--hard-y, var(--hard-x, var(--hard-#{$default-color})));
      }
    }

    &:disabled {
      border: 1px solid var(--hard-disabled, var(--#{$default-color}));
      color: var(--hard-disabled, var(--#{$default-color}));
    }
  }

  &.#{bem.$modifier-spaced} {
    margin-bottom: var(--spacing-2);
    margin-top: var(--spacing-2);
  }

  &.#{bem.$modifier-simple} {
    --stroke-color: var(--x, var(--#{$default-color}));
    background: transparent;
    border: 1px solid transparent;
    color: var(--x, var(--#{$default-color}));
    padding: 0;

    > .#{bem.$modifier-underlined} {
      text-decoration: underline;
    }

    &:disabled,
    &:hover,
    &:focus {
      background: transparent;
    }

    &:not(:disabled) {
      &:hover {
        color: var(--soft-x, var(--soft-#{$default-color}));
      }

      &:focus {
        color: var(--hard-x, var(--hard-#{$default-color}));
      }
    }

    &:disabled {
      color: var(--hard-disabled, var(--#{$default-color}));
    }
  }

  &:disabled {
    background: var(--hard-disabled);
    filter: brightness(125%);
  }
}


@keyframes animateArrow {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(var(--final-position));
  }
}
