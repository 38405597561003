/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-block-padding: calc(var(--theme-default-padding) / 2);
$default-inline-padding: var(--theme-default-padding);
$default-width: var(--tooltip-width, 20rem);

.#{bem.$base}.tooltip {
  display: inline-block;
  position: relative;

  > .tooltip-content {
    background: var(--x);
    border: var(--theme-default-border-style) var(--ui-lines);
    border-radius: calc(var(--theme-default-border-radius) / 2);
    opacity: 0;
    overflow: hidden;
    padding: $default-block-padding $default-inline-padding;
    position: absolute;
    transform: translateX(var(--horizontal, 0)) translateY(var(--vertical, 0));
    transition: opacity .2s ease, transform .2s ease;
    visibility: hidden;
    width: $default-width;
    z-index: 10;

    &.top {
      --horizontal: -50%;
      bottom: calc(100% + $default-block-padding);
      left: 50%;
    }

    &.right {
      --vertical: 50%;
      bottom: 50%;
      left: calc(100% + $default-inline-padding);
    }

    &.bottom {
      --horizontal: -50%;
      left: 50%;
      top: calc(100% + $default-block-padding);
    }

    &.left {
      --vertical: 50%;
      bottom: 50%;
      right: calc(100% + $default-inline-padding);
    }
  }

  > .tooltip-trigger-wrapper {
    cursor: pointer;

    &:is(:hover, :focus-visible, :active) + .tooltip-content {
      opacity: 1;
      transition-delay: 200ms;
      visibility: visible;
    }
  }
}
