/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-border-radius:1em;

.#{bem.$base}.photo-card {
  figure {
    img {
      border-radius: $default-border-radius;
      width: 100%;
    }

    figcaption {
      color: var(--subtitle);
      font-size: calc(var(--s-1) * 1em);
    }
  }
}
