/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- exception */
/* stylelint-disable max-nesting-depth -- exception */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-flex-separator:calc(1em / 2) calc(.75em / 2);
$default-transition:var(--theme-default-transition);
$default-label-padding: .3em 1em calc(2em - .3em);
$default-label-height: .5em;
$default-input-border-radius: var(--theme-default-input-border-radius);
$default-border: var(--theme-default-input-border);
$hover-border: var(--theme-hover-input-border);
$focus-border: var(--theme-focus-input-border);
$default-background: var(--background-inputs);
$disabled-background: var(--background-inputs-30);

.#{bem.$base}.choices-input {
  display: flex;
  flex-direction: column;

  > .#{bem.$base}.form-label {
    margin-bottom: var(--gap);
  }

  > .choices {
    display: flex;
    flex-wrap: wrap;
    gap: $default-flex-separator;

    &.space-between {
      justify-content: space-between;
    }

    >.choice {
      display: flex;

      input {
        opacity: 0;
        position: fixed;
        visibility: none;
        z-index: -1;
      }

      input:disabled + label {
        background-color: $disabled-background;
      }

      > label {
        background-color: $default-background;
        border: $default-border;
        border-radius: $default-input-border-radius;
        color: var(--paragraph);
        cursor: pointer;
        display: block;
        height: $default-label-height;
        max-width: 100%;
        padding: $default-label-padding;
        position: relative;
        transition: $default-transition;
        white-space: nowrap;

        &:not(.disabled) {
          &:hover {
            border: $hover-border;
          }
        }
      }

      input:checked + label {
        background: var(--x);
        color: var(--on-x);
      }
    }
  }
}

