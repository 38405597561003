/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-padding: 1em;

.#{bem.$base}.text-steps {
  grid-column: 1 / -1;
  grid-template-columns: repeat(var(--columns), 1fr);
  padding: $default-padding 0;

  .description {
    grid-column: 2 / -1;

    > p {
      color: var(--metadata);
    }
  }

  .number {
    grid-column: 1 / span 1;
  }

  .title {
    grid-column: 1 / -1;
  }
}
