/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-universal -- exception */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-spacing: var(--gap);

.#{bem.$base}.snap-scroller {
  display: flex;
  gap: $default-spacing;
  -ms-overflow-style: none;
  overflow-x: auto;
  scroll-padding: calc($default-spacing * 2);
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  >* {
    scroll-snap-align: start;

    &:first-child {
      margin-left: $default-spacing;
    }
  }

  &:not(.desktop-no-scroll) {
    >* {
      &:nth-child(2) {
        margin-left: $default-spacing;
      }
    }
  }

  &::after {
    content: "";
    min-width: calc($default-spacing / 2);
  }

  @include mixins.media($from: $sm-md) {
    scroll-snap-type: none;

    &.desktop-no-scroll {
      overflow: auto;
      -ms-overflow-style: unset;
      scrollbar-width: unset;

      &::after {
        display: none;
      }

      >* {
        min-width: unset;

        &:first-child {
          margin-left: unset;
        }

        &:last-child {
          margin-right: unset;
        }
      }
    }

    &:not(.desktop-no-scroll) {
      >* {
        &:nth-child(2) {
          margin-left: unset;
        }
      }
    }
  }
}
