/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-background: var(--background-near);
$default-border-radius: var(--theme-default-border-radius);
$default-blur: var(--theme-default-blur);
$default-height: 3.5em;
$default-gap: calc(var(--gap) / 2);
$default-padding: var(--theme-default-padding);

.#{bem.$base}.cookie-banner {
  align-items: center;
  backdrop-filter: $default-blur;
  background: $default-background;
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;
  bottom: 0;
  padding: calc($default-padding / 2) $default-padding;
  position: fixed;
  width: 100%;
  z-index: 5;

  > .actions {
    display: flex;
    flex-direction: column;
    gap: $default-gap;

    > button {
      justify-content: center;
      width: 100%;
    }
  }

  > .description p {
    margin: 0;
  }

  @include mixins.media($from: $sm-md) {
    height: $default-height;

    > .actions {
      flex-direction: row;
      justify-self: flex-end;

      > button {
        width: unset;
      }
    }
  }
}
