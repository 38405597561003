$namespace-base-class-name:ds !default;

$element-item:item;
$element-content:content;
$element-heading:heading;
$element-subtitle:subtitle;
$element-item:item;
$element-section:section;

$modifier-active:active;
$modifier-attached:attached;
$modifier-animated:animated;
$modifier-blur:blur;
$modifier-clear:clear;
$modifier-compact:compact;
$modifier-image:image;
$modifier-ghost:ghost;
$modifier-open:open;
$modifier-simple:simple;
$modifier-spaced:spaced;
$modifier-gradient:gradient;
$modifier-underlined:underlined;
$modifier-disabled:disabled;

$wrapper-group:group;

// Simplified exports ( as namespace.$class )
$base:$namespace-base-class-name !default;


// The :export block makes the variables available to Javascript
// https://css-tricks.com/getting-javascript-to-talk-to-css-and-sass/

:export {
  // Bear in mind the following will be JS variables. This is why we use camelCase.
  base: $base;
  elementcontent: $element-content;
  elementheading: $element-heading;
  elementitem: $element-item;
  elementsection: $element-section;
  elementsubtitle: $element-subtitle;
  modifieractive: $modifier-active;
  modifieranimated: $modifier-animated;
  modifierattached: $modifier-attached;
  modifierblur: $modifier-blur;
  modifierclear: $modifier-clear;
  modifiercompact: $modifier-compact;
  modifierdisabled: $modifier-disabled;
  modifierimage: $modifier-image;
  modifierghost: $modifier-ghost;
  modifieropen: $modifier-open;
  modifiersimple: $modifier-simple;
  modifierspaced: $modifier-spaced;
  modifierunderlined: $modifier-underlined;
  wrappergroup: $wrapper-group;
}
