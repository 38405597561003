/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-rating-icon-margin: .5em;
$default-padding: .2em;
$default-transition: all .2s;

.#{bem.$base}.ratings-input {
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  >.stars {
    display: flex;

    >:not(:last-child) {
      margin-right: $default-rating-icon-margin;
    }

    > .#{bem.$base}.rating {
      display: flex;

      > input {
        opacity: 0;
        position: absolute;
        visibility: none;
        z-index: -1;
      }

      > label {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: $default-padding;
        transition: $default-transition;
      }
    }

    .label-text {
      align-self: flex-end;
      margin-bottom: 0;
    }
  }
}
