/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-height:var(--height, 1em);
$default-border:none;//2px solid var(--grid);
$default-transition-duration:.5s;
$default-color:var(--x, var(--main));
$default-border-radius:var(--theme-border-radius, 1.875rem);

.#{bem.$base}.progress-bar {
  background-color: var(--progress-bar-background-color);
  border: $default-border;
  height: $default-height;
  position: relative;

  &::after {
    background: $default-color;
    content: " ";
    font-size: 1px;
    height: 100%;
    left: 0;
    line-height: 1px;
    outline: $default-border;
    position: absolute;
    top: 0;
    transition: width $default-transition-duration ease-in-out;
    white-space: pre;
    width: calc(var(--progress) * 1%);
    z-index: 1;
  }

  &.rounded {
    border-radius: $default-border-radius;

    &::after {
      border-radius: $default-border-radius;
    }
  }

  &.#{bem.$modifier-attached} {
    border: unset;
    border-bottom: $default-border;
  }
}


