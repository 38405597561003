/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- required here */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-image-border-radius: 50%;
$default-image-size: 3em;
$default-padding: var(--gap);

.#{bem.$base}.testimonial-card {
  color: var(--paragraph);
  display: flex;
  justify-content: space-between;

  > .#{bem.$base}.section {
    >.rating {
      display: flex;
      gap: calc($default-padding / 2);

      >.icon {
        color: var(--x);
      }
    }

    > .labels {
      display: flex;
      gap: calc($default-padding / 2);
      margin-top: var(--gap);

      .label {
        background-color: var(--background-near);
        color: var(--paragraph);
      }
    }

    > .person {
      align-items: center;
      display: flex;
      gap: $default-padding;

      .image {
        border-radius: $default-image-border-radius;
        height: $default-image-size;
        object-fit: cover;
        width: $default-image-size;
      }

      .details {
        display: flex;
        flex-direction: column;

        .name {
          margin: 0;
        }

        .role {
          color: var(--soft-paragraph);
          margin: 0;
        }
      }
    }
  }
}
