/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-dimensions: 2em;
$mobile-dimensions: 2.75em;

.#{bem.$base}.social-media-share-button {
  > button {
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 3em;
    color: var(--x);
    cursor: pointer;
    font-family: "icons", sans-serif;
    height: $default-dimensions;
    transition: all .3s;
    width: $default-dimensions;

    @include mixins.media($to:$sm-md) {
      height: $mobile-dimensions;
      width: $mobile-dimensions;
    }

    &:hover {
      background: var(--x);
      color: var(--on-x);
    }

    &:focus,
    &:active {
      background: var(--soft-x);
      color: var(--on-x);
    }
  }
}
