/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

.#{bem.$base}.expandable-lexical-preview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--gap);
  position: relative;
  width: 100%;


  &.collapsed {
    flex-direction: row;
  }

  > .title {
    margin: 0;
  }

  > .#{bem.$base}.button {
    align-items: center;
    border: 1px solid var(--outline-inputs);
    display: flex;
  }

  > .#{bem.$base}.expand-button {
    border: 1px solid var(--outline-inputs);
    position: absolute;
    right: 1em;
    top: 5.5rem;
  }
}
