/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.interactive-map {
  display: inline-block;
  position: relative;

  > svg {
    width: 100%;
  }

  @include mixins.media($from: $sm-md) {
    margin: 0 3em;
  }
}
