/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

.#{bem.$base}.draggable-item {
  cursor: move;
  list-style: none;

  &.dragging {
    opacity: .5;
  }
}
