/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-margin: 1rem;

.#{bem.$base}.builder-section {
  border-bottom: var(--theme-default-border-style) var(--on-background-inputs);


  > .section-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  > .#{bem.$base}.editor-input {
    margin-top: $default-margin;
  }

  > form {

    /* stylelint-disable selector-max-compound-selectors -- required */

    > .#{bem.$base}.drag-and-drop {
      >.drag-over {
        > .#{bem.$base}.input-builder {
          border: 1px dashed var(--ui-lines);
        }
      }
    }

    > .add-question-cta {
      align-items: center;
      background-color: transparent;
      border: 0;
      color: var(--hard-interactive);
      cursor: pointer;
      display: flex;
      gap: var(--gap);
      margin-block: calc($default-margin * 1.5);
      margin-left: auto;
      padding: 0;
      transition: all .2s;

      &:hover {
        color: var(--interactive);

        > .icon-container {
          background-color: var(--interactive);
        }
      }

      > .icon-container {
        align-items: center;
        background-color: var(--hard-interactive);
        border-radius: 50%;
        display: flex;
        height: 1.6rem;
        justify-content: center;
        width: 1.6rem;

        > .icon {
          color: var(--on-interactive);
        }
      }
    }
  }
}


