/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-padding: 1rem;
$default-margin: 1rem;
$files-per-row: 3;
$total-gaps: $files-per-row - 1;
$combined-gap: calc(var(--gap) * $total-gaps);
$width-without-gaps: calc(100% - $combined-gap);
$default-file-width: calc($width-without-gaps / $files-per-row);

.#{bem.$base}.file-preview {
  border: var(--theme-default-border-style) var(--ui-lines);
  border-radius: calc(var(--theme-default-border-radius) / 2);
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) / 4);
  padding: $default-padding;
  width: $default-file-width;

  > .identity {
    align-items: center;
    display: flex;
    gap: calc(var(--gap) / 2);
    justify-content: space-between;

    > .type {
      padding-block: calc($default-padding / 4);
      padding-inline: calc($default-padding / 2);

      // TODO: update the colors to use variables. These are colors from the new DS
      &.pdf,
      &.broken,
      &.unknown {
        background-color: #fdead7;
        color: #b93814;
      }

      &.file {
        background-color: #eef2f6;
        color: #364151;
      }

      &.txt {
        background-color: #d1dfff;
        color: #004eeb;
      }


      &.vid {
        background-color: #ebe9fe;
        color: #5a25dc;
      }

      &.img {
        background-color: #cbfbef;
        color: #107569;
      }

      &.aud {
        background-color: #fef7c3;
        color: #a15c08;
      }
    }

    > .name {
      color: var(--on-x);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .#{bem.$base}.button {
      margin-left: auto;
    }
  }

  > .progress-status {
    > .status {
      align-items: center;
      color: var(--x);
      display: flex;
      gap: calc(var(--gap) / 2);
      margin-bottom: calc($default-margin / 4);

      > .icon {
        background-color: var(--x);
        border-radius: 50%;
        color: var(--on-x);
        font-size: calc(var(--s-2) * 1rem);
        padding: calc($default-padding / 4);
      }
    }
  }
}
