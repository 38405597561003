/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-padding: 1rem;
$default-margin: 1rem;
$default-list-width: var(--action-button-width, 18rem);

.#{bem.$base}.actions-container {
  position: relative;

  > button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  > .#{bem.$base}.popover {
    background-color: var(--background-cards);
    border: var(--theme-default-border-style) var(--main2);
    border-radius: calc(var(--theme-default-border-radius) / 2);
    cursor: default;
    padding: $default-padding;
    width: $default-list-width;

    > p {
      margin: 0;
    }

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      white-space: nowrap;

      > .option {
        cursor: pointer;
        padding-block: calc($default-padding / 4);

        &:not(.with-check-mark) {
          padding-inline: $default-padding;
        }

        /* stylelint-disable-next-line selector-max-compound-selectors -- required */
        > button {
          background: transparent;
          border: 0;
          color: var(--paragraph);
          cursor: pointer;
          font-size: calc(var(--s0) * .875rem);
          text-align: left;
          width: 100%;
        }
      }
    }
  }
}


