/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-margin: 1rem;

.#{bem.$base}.builder {
  > form {
    > .section-footer {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: calc($default-margin * 1.5);


      >.add-section-cta {
        --color: var(--interactive);
        --border-color: var(--interactive);
        align-items: center;
        background-color: transparent;
        border: 0;
        color: var(--color);
        cursor: pointer;
        display: flex;
        gap: calc(var(--gap) / 1.5);
        padding: 0;
        transition: all .2s;

        &:hover {
          --color: var(--soft-interactive);
          --border-color: var(--soft-interactive);
        }

        /* stylelint-disable-next-line selector-max-compound-selectors -- required */
        > .icon-container {
          align-items: center;
          border: 2px solid var(--border-color);
          border-radius: 50%;
          display: flex;
          height: 1.6rem;
          justify-content: center;
          width: 1.6rem;
        }
      }

      > .section-navigation {
        align-items: center;
        display: flex;
        flex: .4;
        gap: var(--gap);

        /* stylelint-disable-next-line selector-max-compound-selectors -- required */
        > span {
          white-space: nowrap;
        }

        > .#{bem.$base}.select-input {
          flex: auto;
        }
      }
    }
  }
}
