/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.checkbox {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .#{bem.$base}.form-label {
    margin-bottom: var(--gap);
  }
}
