/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-width: 4.5rem;

.#{bem.$base}.font-size-drop-down {
  align-items: center;
  background-color: var(--x);
  border: 0;
  color: var(--on-background-inputs);
  cursor: pointer;
  display: flex;
  gap: calc(var(--gap) / 2);
  margin-inline: calc(var(--theme-default-padding) / 4);
  padding: calc(var(--theme-default-padding) / 2);
  position: relative;

  &:hover {
    background: var(--hard-x);
    border-radius: calc(var(--theme-default-border-radius) / 2);
  }

  > .#{bem.$base}.popover {
    background-color: var(--background-far);
    border: 1px solid var(--hard-background-cards);
    border-radius: calc(var(--theme-default-border-radius) / 2);
    color: var(--heading);
    display: flex;
    flex-direction: column;
    padding: calc(var(--theme-default-padding) / 2);
    width: $default-width;

    > .item {
      align-items: center;
      background-color: var(--x);
      border: 0;
      border-radius: calc(var(--theme-default-border-radius) / 2);
      color: var(--paragraph);
      cursor: pointer;
      display: flex;
      gap: calc(var(--gap) / 2);
      padding: calc(var(--theme-default-padding) / 2);
      transition: all .2s;

      &:hover {
        background-color: var(--hard-x);
      }

      &.active {
        background-color: var(--hard-ui-main);
      }
    }
  }
}
