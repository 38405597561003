/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-gap: 3rem;

.#{bem.$base}.quote {
  border-left: 3px solid var(--x);
  display: flex;
  flex-direction: column;

  > .blockquote {
    margin: 0;

    > .h2 {
      margin-left: 0;
      margin-top: 0;
    }

    > .quotation {
      margin-left: $default-gap;
    }
  }

  > .figcaption {
    margin-left: $default-gap;
    padding: 0;

    > cite {
      font-style: normal;
    }
  }
}
