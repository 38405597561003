/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-padding: .55em .75em;
$default-symbol-left: 1em;
$default-padding-with-symbol:
  .55em
  calc($default-symbol-left - 1em)
  .55em
  calc($default-symbol-left + 1em);
$default-input-border-radius: var(--theme-default-input-border-radius);
$default-border: var(--theme-default-input-border);
$hover-border: var(--theme-hover-input-border);
$focus-border: var(--theme-focus-input-border);
$default-background: var(--background-inputs);
$disabled-background: var(--background-inputs-30);

.#{bem.$base}.text-input {
  display: flex;
  flex-direction: column;

  > .#{bem.$base}.form-label {
    margin-bottom: var(--gap);
  }

  &.has-symbol {
    > .input-wrapper {
      position: relative;

      &::before {
        color: var(--y);
        content: var(--symbol);
        left: $default-symbol-left;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    input {
      padding: $default-padding-with-symbol;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin-right: calc($default-symbol-left / 4);
      }
    }
  }

  .input {
    background-color: $default-background;
    border: $default-border;
    border-radius: $default-input-border-radius;
    color: var(--y);
    outline: none;
    padding: $default-padding;
    width: 100%;

    &::placeholder {
      color: var(--metadata);
    }

    &:disabled {
      background-color: $disabled-background;
    }

    &:not(:disabled) {
      &:hover,
      &:active {
        border: $hover-border;
      }

      &:focus {
        border: $focus-border;
      }
    }
  }
}
