/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-padding-bottom: .6em;
$default-padding-side: 1.75em;
$default-padding-top: .75em;

$compact-padding: $default-padding-top*.4 $default-padding-side*.4 $default-padding-bottom*.4;
$default-padding: $default-padding-top $default-padding-side $default-padding-bottom;

$default-color: primary;
$default-image-size: 2em;
$default-font-size: calc(1em * var(--s-1));

.#{bem.$base}.label {
  align-items: center;
  background: var(--x, var(--#{$default-color}));
  border-radius: 3em;
  color: var(--on-x, var(--on-#{$default-color}));
  display: inline-flex;
  font-size: $default-font-size;
  gap: calc(var(--gap) / 2);
  padding: $default-padding;
  white-space: nowrap;
  &.#{bem.$modifier-compact} {
    padding: $compact-padding;
  }

  &.#{bem.$modifier-ghost} {
    background: transparent;
    border: 1px solid var(--x, var(--#{$default-color}));
    color: var(--x, var(--#{$default-color}));
  }
}
