@use "@pareto-engineering/bem";

.#{bem.$base}.code-editor {
  border-radius: 1em;
  height: var(--height);
  overflow: auto;
  -ms-overflow-style: none;
  resize: both;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > .cm-editor {
    height: 100%;

    .cm-gutters {
      display: flex;
      justify-content: flex-end;
      width: var(--gutter-width);
    }
  }
}
