/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-background: var(--background-near);
$default-border-radius: var(--theme-default-border-radius);
$default-border-color: var(--background-far);
$default-border-style: $default-border-radius $default-border-radius 0 0;
$default-button-padding: 1.75em;
$default-desktop-button-padding: 2.75em;
$default-gap: var(--gap);
$default-side-padding: $default-gap;
$default-post-text-color: var(--soft-paragraph);
$default-nav-button-padding:1.75em 0 3em;
$default-desktop-nav-padding: 2.75em;

.#{bem.$base}.inline-conversion-form {
  background-color: $default-background;
  overflow: hidden;
  position: sticky;
  top: var(--theme-default-header-height-offset);

  .form-input:first-of-type > p {
    margin-top: 0;
  }

  .form-content {
    margin: auto;
  }

  .relay-error > p {
    color: var(--error);
    font-size: smaller;
  }

  > .post-text {
    > p {
      color: $default-post-text-color;

      > a {
        color: $default-post-text-color;
      }
    }
  }

  > div {
    padding-left: $default-side-padding;
    padding-right: $default-side-padding;
  }
}

.#{bem.$base}.fixed-conversion-wrapper {
  align-items: center;
  background: $default-background;
  border-radius: $default-border-style;
  bottom: 0;
  padding-left: $default-side-padding;
  padding-right: $default-side-padding;
  position: fixed;
  width: 100%;
  z-index: 1;

  > .launch-text,
  > .launch-button {
    white-space: nowrap;
  }

  > .launch-button {
    justify-self: end;
  }
}

.#{bem.$base}.fixed-conversion-inner {
  background: $default-background;
  border: 1px solid $default-border-color;
  border-radius: $default-border-style;
  bottom: 0;
  // used to position the close button, content and navigation buttons
  grid-template-rows: auto 1fr auto;
  height: 100%;
  min-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $default-side-padding;
  position: fixed;
  transform: translateY(100%);
  z-index: 3;

  .relay-error > p {
    color: var(--error);
    font-size: smaller;
  }

  > .progress-bar {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.#{bem.$modifier-open} {
    transform: translateY(0);
    transition: transform .3s ease-in-out;
  }

  .navigator-section {
    padding-bottom: $default-button-padding;
    padding-top: $default-button-padding;
  }

  .bottom-banner {
    background: var(--main);
    bottom: 0;
    padding: calc($default-gap / 4);
    position: absolute;
    width: 100vw;

    > span {
      align-items: content;
      color: var(--on-main);
      display: flex;
      justify-content: center;

      > a {
        color: var(--on-main);
        text-decoration: underline;
      }
    }
  }

  @include mixins.media($from: $sm-md) {
    height: 93vh;
    overflow: hidden;

    .navigator-section {
      padding-top: $default-desktop-button-padding;
    }

    .content {
      align-self: center;
    }
  }

  .post-text {
    > p {
      color: $default-post-text-color;

      > a {
        color: $default-post-text-color;
      }
    }
  }

  > .close {
    justify-content: end;
    padding-top: $default-button-padding;
  }

  > .content {
    > .interactive-section {
      > iframe {
        border: 0;
        height: 65vh;
        width: 100%;
      }
    }

    @include mixins.media($from: $sm-md) {
      &.column {
        align-self: unset;
        display: grid;
        grid-column: 2 / span 11;
        overflow: hidden;

        > .description-section {
          grid-column: 1 / span 6;
        }

        > .interactive-section {
          grid-column: 7 / span 6;
          position: relative;
          scale: .7;
        }
      }
    }
  }
}

.#{bem.$base}.navigator {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $default-nav-button-padding;

  @include mixins.media($from: $sm-md) {
    padding-bottom: $default-desktop-nav-padding;
    padding-top: unset;
  }

  > .hidden {
    visibility: hidden;
  }
}
