/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "react-color-palette/css";

$default-width: 20rem;

.#{bem.$base}.color-picker {
  cursor: pointer;
  position: relative;

  > button {
    align-items: center;
    background-color: var(--x);
    border: 0;
    color: var(--on-background-inputs);
    display: flex;
    gap: calc(var(--gap) / 2);
    padding: calc(var(--theme-default-padding) / 2);

    &:hover {
      background: var(--hard-x);
      border-radius: calc(var(--theme-default-border-radius) / 2);
    }
  }


  > .#{bem.$base}.popover {
    background-color: var(--background-far);
    border: 1px solid var(--hard-background-cards);
    border-radius: calc(var(--theme-default-border-radius) / 2);
    color: var(--heading);
    padding: calc(var(--theme-default-padding) / 2);
    width: $default-width;
  }
}
