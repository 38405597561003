/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;
@use "@aztlan/swatch/src/collections/palettes/monochrome";

$default-border-radius:50%;
$default-person-border-radius:2em;
$default-padding:var(--u);
$default-horizontal-margin: .5em;

$default-grid-gap: 1em;
$default-margin: 1em;
$default-image-margin: var(--default-image-margin, #{$default-margin});
$default-color: var(--default-color, var(--paragraph));

.#{bem.$base}.people {
  display: grid;
  gap: $default-grid-gap;
  grid-template-columns: repeat(auto-fill, minmax(var(--column-width), 1fr));


  .person {
    align-items: center;
    border-radius: $default-person-border-radius;
    display: flex;

    padding-right: $default-padding;

    .image {
      border-radius: $default-border-radius;
      height: var(--image-size);
      margin-right: $default-image-margin;
      width: var(--image-size);
    }

    .details {
      align-items: flex-start;
      color: $default-color;
      display: flex;
      flex-direction: column;

      p:only-child {
        margin: 0;
      }

      .name {
        color: $default-color;
        margin-bottom: .1em;
      }

      .role {
        opacity: .5;
      }
    }
  }
}


