/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-border: var(--theme-default-border-style) var(--metadata);

.#{bem.$base}.popover {
  background-color: var(--x);
  border: $default-border;
  position: absolute;
  z-index: 1;

  &:not(.open) {
    display: none;
  }

  &.bottom {
    top: 100%;
    // bottom: 0;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.top {
    bottom: 100%;
    top: auto;
  }
}
