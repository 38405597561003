/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- exception */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-text-color: var(--paragraph);
$default-color: var(--x);
$default-border: var(--theme-border-style) $default-color;
$default-margin: var(--gap);
$default-padding: var(--gap);
$solution-item-padding: calc($default-padding/2) 0;
$default-border-color: var(--hard-background-cards);
$default-background: var(--background-cards-50);
$default-blur: var(--theme-default-blur);

.#{bem.$base}.footer > .wrapper {
  background: $default-background;
  border: 1px solid $default-border-color;
  border-bottom: none;
  border-radius: var(--theme-default-border-radius) var(--theme-default-border-radius) 0 0;
  padding: $default-padding;
  padding-top: var(--theme-large-text-spacing);

  &.#{bem.$modifier-spaced} {
    padding-bottom: var(--theme-default-conversion-form-footer-spacing);
  }

  .contact-info {
    text-decoration: underline;
  }

  > .title {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  > .logo {
    align-items: center;
    display: flex;
    gap: $default-padding;
    margin: 0;

    > .h3 {
      margin: 0;
    }
  }

  .remove-top-margin {
    margin-top: 0;
  }

  .link-wrapper {
    padding: calc($default-padding/2) 0;
  }

  &.#{bem.$modifier-blur} {
    backdrop-filter: $default-blur;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  a {
    color: $default-text-color;
    text-decoration: none;
  }

  svg {
    margin-left: -.3em;
    margin-top: -1.5em;
    width: 4rem;
  }

  .logo-content {
    .logo-text {
      color: $default-color;
      margin-top: $default-margin;
    }
  }


  > .footer-nav {
    color: $default-color;
  }

  > .social-links {
    display: flex;
    flex-wrap: wrap;
    gap: calc($default-padding * 2);
    padding-top: calc($default-padding / 2);

    > a > button {
      background: transparent;
      color: $default-text-color;
      padding: 0;
    }

    @include mixins.media($from: $sm-md) {
      justify-content: space-between;
    }
  }

  .#{bem.$base}.theme-selector {
    padding: calc($default-padding/2) 0;
  }

  .copyright-info {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: $default-padding;
    padding-top: $default-padding;
  }

  > .bottom-section {
    @include mixins.media($from: $sm-md) {
      display: flex;
      justify-content: space-between;

      > .privacy-info {
        display: flex;
        gap: calc($default-padding * 2);
      }
    }
  }
}
