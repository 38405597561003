/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-border-radius: var(--theme-default-border-radius);
$default-height: 10em;
$default-gap: var(--gap);

.#{bem.$base}.article-card-wrapper {
  text-decoration: none;

  &:hover {
    .#{bem.$base}.article-card {
      background-color: var(--hard-background-near);
    }
  }

  > .#{bem.$base}.article-card {
    background: var(--background-near);

    > .title {
      margin-block-start: calc($default-gap * 1.5);
    }

    > .image-header {
      height: $default-height;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    > .data {
      display: flex;
      gap: $default-gap;
    }
  }
}
