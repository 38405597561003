/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;


$default-gap: calc(var(--gap) / 2);
$default-padding: .55em .75em;
$default-input-border-radius: var(--theme-default-input-border-radius);
$default-border: var(--theme-default-input-border);
$default-icon-color: var(--soft-ui-icons);

.#{bem.$base}.toolbar-plugin {
  align-items: center;
  border-inline: $default-border;
  border-top: $default-border;
  border-top-left-radius: $default-input-border-radius;
  border-top-right-radius: $default-input-border-radius;
  display: flex;
  flex-wrap: wrap;
  padding-block: calc(var(--theme-default-padding) / 4);

  > .ds + .group {
    border-left: 1px solid var(--soft-background-inputs);
  }

  > .group {
    display: flex;
    gap: calc(var(--gap) / 2);

    > button {
      background: var(--x);
      border: 0;
      border-radius: calc(var(--theme-default-border-radius) / 2);
      color: $default-icon-color;
      cursor: pointer;
      margin: 0;
      padding: $default-padding;

      &:hover,
      &.active {
        background: var(--hard-x);
      }
    }

    &:not(.#{bem.$base}) {
      padding-inline: calc(var(--theme-default-padding) / 4);
    }


    &:not(:last-child, .#{bem.$base}) {
      border-right: 1px solid var(--soft-background-inputs);
    }
  }
}
