/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-dot-size: .4em;

.#{bem.$base}.point {
  background-color: var(--highlighted);
  border: 1px solid var(--on-highlighted);
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(var(--soft-x), .4);
  cursor: pointer;
  height: $default-dot-size;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all .3s ease;
  width: $default-dot-size;
  will-change: transform, box-shadow;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    transform: translate(-50%, -50%) scale3d(1.35, 1.35, 1);
    z-index: 1;

    >.#{bem.$base}.popover {
      display: block;
      min-width: fit-content;
      position: relative;
      white-space: nowrap;
      z-index: 2;
    }
  }

  >.#{bem.$base}.popover {
    color: var(--on-x);
    padding: calc(var(--gap) / 2);
  }

  @include mixins.media($from: $sm-md) {
    height: calc($default-dot-size * 2.5);
    width: calc($default-dot-size * 2.5);
  }
}
