/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-padding: 1.5em;

.#{bem.$base}.solution-card-wrapper {
  text-decoration: none;

  > .#{bem.$base}.solution-card {
    .title,
    .icon {
      color: var(--heading);
    }

    .title {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    > .bottom {
      display: flex;
      justify-content: end;
    }

    &:hover {
      background-color: var(--hard-background-cards);
    }
  }

  &:focus {
    > .#{bem.$base}.solution-card {
      box-shadow: 2px 2px 4px 0 var(--grey);
    }
  }
}
