/* @pareto-engineering/generator-front 1.0.12 */


@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-spacing-size: 1em;
$default-padding: .55em .75em;
$default-bg-gradient: var(--background-far);
$default-input-border-radius: var(--theme-default-input-border-radius);
$default-border: var(--theme-default-input-border);
$hover-border: var(--theme-hover-input-border);
$focus-border: var(--theme-focus-input-border);
$default-background: var(--background-inputs);
$disabled-background: var(--background-inputs-30);
$default-gap: var(--gap);


.#{bem.$base}.select-input {
  > .#{bem.$base}.single {
    display: flex;
    flex-direction: column;

    > .#{bem.$base}.form-label {
      margin-bottom: var(--gap);
    }

    .select-wrapper {
      background-color: $default-background;
      border: $default-border;
      border-radius: $default-input-border-radius;
      display: flex;
      flex-direction: column;
      padding: $default-padding;
      padding-right: 0;
      position: relative;

      &:not(.disabled) {
        &:hover,
        &:active {
          border: $hover-border;
        }

        &:focus {
          border: $focus-border;
        }
      }

      &.disabled {
        background: $disabled-background;
      }

      &::placeholder {
        color: var(--metadata);
      }

      &::after {
        border-radius: $default-input-border-radius;
      }

      >.#{bem.$base}.loading-circle {
        position: absolute;
        right: $default-spacing-size;
        top: 50%;
        transform: translateY(-50%);
      }

      select {
        appearance: none;
        background-color: $default-background;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDEuNUwxMCAxMC41TDEgMS41IiBzdHJva2U9IiM0QzRENTMiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4=");
        background-position: calc(100% - $default-spacing-size);
        background-repeat: no-repeat;
        background-size: $default-spacing-size;
        padding-right: $default-spacing-size;

        &.input {
          border: none;
          color: var(--y);
          outline: none;
          width: 100%;

          /* stylelint-disable-next-line max-nesting-depth -- required */
          &:disabled {
            background: transparent;
          }
        }
      }
    }
  }

  > .#{bem.$base}.multiple {
    display: flex;
    flex-direction: column;
    outline: none;
    position: relative;

    > .#{bem.$base}.form-label {
      margin-bottom: var(--gap);
    }


    .#{bem.$base}.popover {
      border: $default-border;
      border-radius: $default-input-border-radius;
      width: 100%;

      >.menu {
        list-style: none;
        margin: 0;
        outline: 0;
        padding: 0;

        /* stylelint-disable selector-max-compound-selectors -- required */
        >.item {
          border-radius: $default-input-border-radius;
          padding: $default-padding;

          /* stylelint-disable max-nesting-depth -- required */
          > p {
            margin: 0;
          }

          &.#{bem.$modifier-active}:not(.disabled) {
            background-color: var(--y);

            > p {
              color: var(--on-y);
            }
          }
        }
      }
    }


    > .input-container {
      position: relative;

      > .input {
        background: $default-background;
        border: $default-border;
        border-radius: calc(var(--theme-default-border-radius) / 2);
        color: var(--paragraph);
        outline: none;
        padding: $default-padding;
        width: 100%;

        &::placeholder {
          color: var(--metadata);
        }

        &:disabled {
          background-color: $disabled-background;
          color: var(--paragraph);
        }

        &:not(:disabled) {
          &:hover,
          &:active {
            border: $hover-border;
          }

          &:focus {
            border: $focus-border;
          }
        }
      }
    }


    >.selected-items {
      display: flex;
      flex-wrap: wrap;
      gap: calc($default-gap / 2);
      margin-bottom: calc($default-gap / 2);

      >.item {
        >.#{bem.$base}.button {
          align-items: center;
          display: flex;
          gap: calc($default-gap / 2);
        }

        .close {
          font-size: calc(var(--s-3) * 1em);
        }
      }
    }
  }
}
