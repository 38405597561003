/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.sticky-steps {
  > .steps:last-child {
    padding-bottom: var(--spacing-2);
  }

  @include mixins.media($from:$sm-md) {
    > .steps {
      position: relative;

      > .step > .step-text {
        position: sticky;
        top: var(--theme-default-header-height-offset);
      }
    }
  }
}
