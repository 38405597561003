/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-padding: 1rem;
$default-margin: 1rem;
$default-list-width: var(--action-button-width, 18rem);

.#{bem.$base}.input-builder {
  border: var(--theme-default-border-style) var(--ui-lines);
  border-radius: calc(var(--theme-default-border-radius) / 2);
  display: flex;
  margin-top: $default-margin;
  padding: $default-padding;

  > .drag-icon {
    margin-right: $default-margin;
  }

  > .input-options-container {
    flex: auto;

    > .controls-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: $default-margin;

      .#{bem.$base}.select-input {
        flex: .3;
      }

      >.controls {
        display: flex;
        gap: var(--gap);

        /* stylelint-disable selector-max-compound-selectors -- required */
        > .is-required {
          display: flex;
        }

        /* stylelint-disable selector-max-universal -- required */
        > * {
          height: fit-content;
        }

        > button {
          background-color: transparent;
          border: 0;
          cursor: pointer;

          /* stylelint-disable max-nesting-depth -- required */
          > .ai-icon,
          .icon {
            font-size: calc(var(--s1) * 1rem);
          }
        }
      }
    }

    > .input-name-description {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      margin-bottom: $default-margin;
    }

    > .input-options {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      > p {
        margin: 0;
      }

      > .option-container {
        align-items: center;
        display: flex;
        gap: calc(var(--gap) / 2);

        > .input-icon-sample {
          border: var(--theme-default-border-style) var(--outline-inputs);
          border-radius: var(--theme-default-border-radius);
          cursor: default;
          height: 1.6rem;
          width: 1.6rem;

          &.checkbox {
            border-radius: calc(var(--theme-default-border-radius) / 4);
          }
        }


        > .#{bem.$base}.text-input {
          flex: .5;
        }

        > .#{bem.$base}.select-input {
          flex: .3;
        }

        > button {
          background-color: transparent;
          border: 0;
          color: var(--ui-icons);
          cursor: pointer;
        }
      }

      >.add-option-cta {
        --color: var(--interactive);
        --border-color: var(--interactive);
        align-items: center;
        align-self: flex-start;
        background-color: transparent;
        border: 0;
        color: var(--color);
        cursor: pointer;
        display: flex;
        gap: calc(var(--gap) / 1.5);
        padding: 0;
        transition: all .2s;

        &:hover {
          --color: var(--soft-interactive);
          --border-color: var(--soft-interactive);
        }

        > .icon-container {
          align-items: center;
          border: 2px solid var(--border-color);
          border-radius: 50%;
          display: flex;
          height: 1.6rem;
          justify-content: center;
          width: 1.6rem;
        }
      }
    }

    > .input-file-options {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      margin-bottom: $default-margin;

      > .specific-file-types {
        display: flex;
      }
    }
  }
}


