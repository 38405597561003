/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- required here */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-gap: calc(var(--gap) / 2);

.#{bem.$base}.expert-card {
  cursor: grab;
  -webkit-text-select: none;
  user-select: none;

  > .#{bem.$base}.section {
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    > img {
      border-radius: var(--theme-default-border-radius);
      height: 10em;
      object-fit: cover;
      overflow: hidden;
      width: 100%;
    }

    .tags,
    .languages {
      display: flex;
      flex-wrap: wrap;
      gap: $default-gap;

      > .#{bem.$base}.label {
        color: var(--paragraph);
      }
    }

    .person {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .name,
    .identifier,
    .role,
    .location {
      margin: 0;
    }

    .stats {
      display: flex;
      gap: $default-gap;
      width: 100%;

      > .stat {
        background: var(--background-near);
        border-radius: var(--theme-default-border-radius);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--gap) calc(var(--gap) * .7);
        width: 50%;

        > .label {
          color: var(--paragraph);
          font-weight: 500;
          margin: 0;
        }

        > .value {
          color: var(--highlighted);
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }

  @include mixins.media($from: $sm-md) {
    // flex-direction: row;
    justify-content: flex-start;

    > .#{bem.$base}.section {
      flex: 1 2 auto;

      &.image-section {
        max-height: 100%;
      }

      .person {
        margin-top: var(--gap);
      }
    }
  }
}
