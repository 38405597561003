/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-spacing: var(--gap);

.#{bem.$base}.group {
  gap: $default-spacing;

  &.snap-scroller {
    margin-left: calc($default-spacing * -1);
    margin-right: calc($default-spacing * -1);
  }

  &.vertical-flexbox {
    display: flex;
    flex-direction: column;
  }

  @include mixins.media($from: $sm-md) {
    &.desktop-grid {
      display: grid;
      grid-template-columns: repeat(var(--columns), 1fr);
      margin-left: unset;
      margin-right: unset;
    }

    &.desktop-vertical-flexbox {
      display: flex;
      flex-direction: column;
    }
  }
}
