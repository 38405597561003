/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}
