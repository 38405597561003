/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-max-image-height: 20em;
$default-gap: var(--gap);
$default-social-media-button-offset: -13px;

.#{bem.$base}.case-study-heading {
  > .title,
  > .subtitle {
    font-weight: 400;
  }

  > .divider {
    border: 0;
    border-bottom: 1px solid var(--ui-lines);
    width: 100%;
  }

  > .illustration {
    border-radius: var(--theme-default-border-radius);
    height: auto;
    max-height: $default-max-image-height;
    min-height: calc($default-max-image-height * .75);
    object-fit: cover;
    width: 100%;
  }

  > .data {
    .value {
      font-weight: 700;
    }

    .social-media-share-buttons {
      display: flex;
      gap: $default-gap;

      > .#{bem.$base}.social-media-share-button {
        margin-left: $default-social-media-button-offset;

        @include mixins.media($from: $sm-md) {
          margin-left: calc($default-social-media-button-offset / 2);
        }
      }
    }
  }
}
