/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable max-nesting-depth -- needed here */
/* stylelint-disable selector-class-pattern -- needed here */
/* stylelint-disable selector-max-compound-selectors -- needed here */
/* stylelint-disable declaration-no-important -- needed here */
/* stylelint-disable font-weight-notation -- needed here */
/* stylelint-disable declaration-block-no-duplicate-properties -- needed here */

@use "@pareto-engineering/bem";

$default-background: var(--background-far);
$default-border: var(--theme-default-input-border);
$default-input-border-radius: var(--theme-default-input-border-radius);
$default-padding: .55em .75em;
$default-spacing-size: calc(var(--gap) / 2);
$rdp-cell-size: 40px;
$rdp-caption-font-size: 1em;
$rdp-accent-color: var(--hard-interactive);
$rdp-outline: 2px solid var(--hard-interactive);
$rdp-selected-color: var(--on-interactive);

.#{bem.$base}.date-picker {
  /* Hide elements for devices that are not screen readers */
  .rdp-vhidden {
    appearance: none;
    appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    border: 0 !important;
    box-sizing: border-box;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    margin: 0;
    overflow: hidden !important;
    padding: 0;
    padding: 0 !important;
    position: absolute !important;
    top: 0;
    width: 1px !important;
  }

  /* Buttons */
  .rdp-button_reset {
    appearance: none;
    appearance: none;
    appearance: none;
    background: none;
    color: inherit;
    cursor: default;
    font: inherit;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .rdp-button_reset:focus-visible {
    /* Make sure to reset outline only when :focus-visible is supported */
    outline: none;
  }

  .rdp-button {
    border: 2px solid transparent;
  }

  .rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: .25;
  }

  .rdp-button:not([disabled]) {
    cursor: pointer;
  }

  .rdp-button:focus-visible:not([disabled]) {
    background-color: $default-background;
    border: $rdp-outline;
    color: inherit;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: $default-background;
  }

  .rdp-months {
    display: flex;
  }

  .rdp-month {
    margin: 0 1em;
  }

  .rdp-month:first-child {
    margin-left: 0;
  }

  .rdp-month:last-child {
    margin-right: 0;
  }

  .rdp-table {
    background-color: $default-background;
    border-collapse: collapse;
    margin: 0;
    max-width: calc($rdp-cell-size * 7);
  }

  .rdp-with_weeknumber .rdp-table {
    border-collapse: collapse;
    max-width: calc($rdp-cell-size * 8);
  }

  .rdp-caption {
    align-items: center;
    background-color: $default-background;
    display: flex;
    justify-content: space-between;
    padding: 0;
    text-align: left;
  }

  .rdp-multiple_months .rdp-caption {
    display: block;
    position: relative;
    text-align: center;
  }

  .rdp-caption_dropdowns {
    display: inline-flex;
    position: relative;
  }

  .rdp-caption_label {
    align-items: center;
    border: 0;
    border: 2px solid transparent;
    color: var(--heading);
    display: inline-flex;
    font-family: inherit;
    font-size: $rdp-caption-font-size;
    font-weight: bold;
    margin: 0;
    padding: 0 .25em;
    position: relative;
    white-space: nowrap;
    z-index: 1;
  }

  .rdp-nav {
    white-space: nowrap;
  }

  .rdp-multiple_months .rdp-caption_start .rdp-nav {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .rdp-nav_button {
    align-items: center;
    border-radius: 100%;
    display: inline-flex;
    height: $rdp-cell-size;
    justify-content: center;
    padding: .25em;
    width: $rdp-cell-size;
  }

  /* ---------- */

  /* Dropdowns  */

  /* ---------- */

  .rdp-dropdown_year,
  .rdp-dropdown_month {
    align-items: center;
    display: inline-flex;
    position: relative;
  }

  .rdp-dropdown {
    appearance: none;
    background-color: transparent;
    border: none;
    bottom: 0;
    cursor: inherit;
    font-family: inherit;
    font-size: inherit;
    left: 0;
    line-height: inherit;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .rdp-dropdown[disabled] {
    color: unset;
    opacity: unset;
  }

  .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: $default-background;
    border: $rdp-outline;
    border-radius: 6px;
  }

  .rdp-dropdown_icon {
    margin: 0 0 0 5px;
  }

  .rdp-head {
    border: 0;
  }

  .rdp-head_row,
  .rdp-row {
    height: 100%;
  }

  .rdp-head_cell {
    font-size: .75em;
    font-weight: 700;
    height: 100%;
    height: $rdp-cell-size;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
  }

  .rdp-tbody {
    border: 0;
  }

  .rdp-tfoot {
    margin: .5em;

    .default-footer {
      display: flex;
      flex-direction: column;

      >.footer-text {
        align-self: center;
      }

      >.time-label {
        align-items: center;
        align-self: first baseline;
        color: var(--heading);
        display: flex;
        margin: 0;
        padding: $default-spacing-size;
      }

      >.time-inputs {
        :not(.multiple) {
          margin-left: $default-spacing-size;
        }

        &.multiple {
          align-items: center;
          align-self: center;
          display: flex;
          gap: $default-spacing-size;
        }

        >input[type="time"] {
          background-color: $default-background;
          border: $default-border;
          border-radius: $default-input-border-radius;
          color: var(--paragraph);
          outline: none;
          padding: $default-padding;

          &::-webkit-calendar-picker-indicator {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDEuNUwxMCAxMC41TDEgMS41IiBzdHJva2U9IiM0QzRENTMiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4=");
            background-position: calc(100% - $default-spacing-size / 2);
            background-repeat: no-repeat;
            background-size: $default-spacing-size;
          }
        }
      }
    }
  }

  .rdp-cell {
    height: 100%;
    height: $rdp-cell-size;
    padding: 0;
    text-align: center;
    width: $rdp-cell-size;
  }

  .rdp-weeknumber {
    font-size: .75em;
  }

  .rdp-weeknumber,
  .rdp-day {
    align-items: center;
    border: 2px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    display: flex;
    height: $rdp-cell-size;
    justify-content: center;
    margin: 0;
    max-width: $rdp-cell-size;
    overflow: hidden;
    width: $rdp-cell-size;
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: $rdp-accent-color;
    color: $rdp-selected-color;
    opacity: 1;
  }

  .rdp-day_outside {
    opacity: .5;
  }

  .rdp-day_selected:focus-visible {
    /* Since the background is the same use again the outline */
    outline: $rdp-outline;
    outline-offset: 2px;
    z-index: 1;
  }

  .rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .rdp-day_range_end.rdp-day_range_start {
    border-radius: 100%;
  }

  .rdp-day_range_middle {
    background-color: var(--interactive);
    border-radius: 0;
  }

  .rdp-nav_icon {
    color: var(--ui-icons);
  }
}
