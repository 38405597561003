/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-border-radius: var(--theme-default-border-radius);
$default-size: 1.2em;

.#{bem.$base}.theme-selector {
  align-items: center;
  display: flex;
  gap: var(--gap);

  > button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;

    >input {
      height: 0;
      visibility: hidden;
      width: 0;
    }

    >label {
      background: var(--interactive);
      border-radius: $default-border-radius;
      cursor: pointer;
      display: block;
      height: $default-size;
      position: relative;
      width: calc($default-size * 2);

      &::after {
        background: var(--background-far);
        border: 1px solid var(--ui-lines);
        border-radius: $default-border-radius;
        content: "";
        height: $default-size;
        left: 0;
        position: absolute;
        top: 0;
        transition: .3s;
        width: $default-size;
      }
    }

    >input:checked + label::after {
      left: $default-size;
    }
  }
}
