/* @pareto-engineering/generator-front 1.0.3 */

@use "@pareto-engineering/bem";

$anim-stroke-dash-offset:2888;
$anim-stroke-dash-array:2999;
$animation-time:2s;
$animation-repeats:1;
$animation-easing:linear;


// TODO cleanup --custom-props

@keyframes draw-stroke {
  0% {
    stroke-dashoffset: var(--svg-origin-stroke-dashoffset, 1000);
  }

  100% {
    stroke-dashoffset: var(--svg-target-stroke-dashoffset, 0);
  }
}

.#{bem.$base}.svg { //TODO or should it apply to svg elements altogether?
  use {
    fill: var(--x, transparent);
    stroke: var(--y, transparent);
    stroke-dasharray: var(--svg-dasharray);
    stroke-dashoffset: var(--svg-dashoffset, 0);
    stroke-width: var(--svg-stroke-width, 2);
    transition: all .3s;
  }

  //&.#{bem.$modifier-animated} {
  &.animated {
    --svg-origin-stroke-dashoffset: #{$anim-stroke-dash-offset};
    --svg-dasharray: #{$anim-stroke-dash-array};
    --svg-animation-time: #{$animation-time};
    --svg-animation-repeats: #{$animation-repeats};
    --svg-animation-easing: #{$animation-easing};

    use {
      animation:
        draw-stroke // animation name
        var(--svg-animation-time) //duration
        var(--svg-animation-easing) // easing
        var(--svg-animation-delay, .3s) //delay TODO ?
        var(--svg-animation-repeats) //repeat
        forwards; //Final keyframe
    }

    &:hover,
    &:focus {
      //--path-animation: dash-a 1s linear 0s 1 forwards;
    }
  }
}
