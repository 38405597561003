/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.milestone {
  width: 100%;

  > img {
    height: 2em;
  }

  > .milestone-info {
    align-items: center;
    display: flex;
    gap: var(--gap);
  }

  @include mixins.media($from:$sm-md) {
    &.has-desktop-divider {
      border-bottom: 1px solid var(--main);
    }
  }
}
