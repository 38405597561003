/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-image-size: 5em;
$default-margin: 1em;

.#{bem.$base}.use-case-card {
  background: var(--background-cards);
  border: 1px solid var(--ui-lines);
  border-radius: var(--theme-default-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--gap);

  > .info {
    margin-bottom: calc($default-margin / 2);

    /* stylelint-disable-next-line selector-max-universal -- required */
    >*:not(:last-child) {
      margin-bottom: calc($default-margin / 2);
    }

    > p {
      margin: 0;
    }

    > img {
      background: var(--background-cards);
      border-radius: 50%;
      max-width: $default-image-size;
    }
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background: var(--hard-background-cards);
    }
  }

  > .path-link {
    align-self: flex-end;
    color: var(--paragraph);

    > :first-child {
      text-decoration: underline;
    }
  }
}
