/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-logos-margin: var(--v) 0;

.#{bem.$base}.customers {
  display: flex;
  flex-direction: column;
  opacity: .8;
  width: 100%;

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-block: 2em;

    @include mixins.media($from:$sm-md) {
      gap: calc(var(--gap) * 1.5);
    }

    .logo {
      flex-basis: 22%;
    }

    @include mixins.media($to:$sm-md) {
      padding-bottom: 4em;
      padding-top: 2em;

      .logo {
        &.center-self {
          margin-inline: auto;
        }
      }
    }
  }

  // Desktop styles
  @include mixins.media($from:$sm-md) {
    .logos {
      flex-wrap: nowrap;
    }
  }
}
