/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-border: 1px solid var(--ui-lines);
$default-border-radius: .5em;
$default-max-height: 10em;
$default-padding: calc(var(--gap) / 2);
$default-margin: var(--gap);

.#{bem.$base}.form-builder {
  > .task-data {
    background: var(--background-inputs);
    border: $default-border;
    border-radius: $default-border-radius;
    color: var(--on-background-far);
    height: $default-max-height;
    margin-bottom: $default-margin;
    max-width: 100%;
    overflow: auto;
    padding: $default-padding;
    resize: vertical;
    text-wrap: wrap;
  }
}
