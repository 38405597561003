/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- exception */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.posts-list {
  > .#{bem.$base}.post {
    display: flex;
    justify-content: space-between;

    > .post-body {
      width: 100%;

      .title {
        color: var(--heading);
        text-decoration: none;

        &:hover,
        &:active {
          color: var(--soft-heading);
          text-decoration: underline;
        }
      }

      .post-stats {
        align-items: center;
        display: flex;
        gap: var(--gap);
        width: 100%;

        .#{bem.$base}.label {
          font-size: calc(1em * var(--s0));
        }

        .word-count {
          color: var(--soft-paragraph);
        }
      }
    }

    > .illustration {
      height: 8em;

      img {
        border-radius: var(--theme-default-border-radius);
        height: 100%;
        object-fit: cover;
      }
    }

    &:not(:last-child) {
      padding-bottom: calc(var(--spacing-2) * 1.5);
    }

    :first-child {
      margin-top: 0;
    }
  }

  @include mixins.media($from: $sm-md) {
    > .#{bem.$base}.post {
      > .illustration {
        height: unset;
        max-height: 15em;
      }
    }
  }
}
