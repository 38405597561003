/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-margin: 1rem;

.#{bem.$base}.renderer {
  > form {
    > .#{bem.$base}.section {
      margin-bottom: calc($default-margin * 2);

      > p {
        margin: 0;
      }
      > .#{bem.$base}.expandable-lexical-preview {
        margin-bottom: $default-margin;
      }
    }

    > .navigator-container {
      display: flex;
      gap: var(--gap);
      justify-content: space-between;

      > .previous-button {
        align-items: center;
      }
    }
  }
}
