/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-padding: calc(var(--gap) * 2);

.#{bem.$base}.tip {
  align-items: center;
  background: var(--x);
  border-radius: var(--theme-default-border-radius);
  display: flex;
  gap: var(--gap);
  padding: $default-padding;
}
