/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

@keyframes transform {
  0%,
  100% {
    border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
  }

  20% {
    border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
  }

  40% {
    border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
  }

  60% {
    border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
  }

  80% {
    border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
  }
}
@keyframes movement_one {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: translate(50%, 20%) rotateY(10deg) scale(1.2);
  }
}
@keyframes movement_two {
  0%,
  500% {
    transform: none;
  }

  50% {
    transform: translate(50%, 20%) rotate(-200deg) scale(1.2);
  }
}

.#{bem.$base}.animated-blobs {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;

  > .shape-blob {
    animation:
      transform 20s ease-in-out infinite both alternate,
      movement_one 40s ease-in-out infinite both;
    background: var(--blob-color);
    border-radius: 30% 50% 20% 40%;
    height: var(--height);
    left: 70%;
    opacity: var(--opacity);
    position: absolute;
    top: 50%;
    width: var(--width);
  }

  > .shape-blob.one {
    animation: transform 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;
    height: calc(var(--height) * 2.5);
    left: -200px;
    top: -150px;
    transform: rotate(-180deg);
    width: calc(var(--width) * 2.5);
  }

  > .shape-blob.two {
    animation: transform 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;
    height: calc(var(--height) * 1.75);
    left: 500px;
    top: -150px;
    transform: rotate(-180deg);
    width: calc(var(--width) * 1.75);
  }
}
