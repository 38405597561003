/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-padding: 1rem;
$default-margin: 1rem;
$default-border-radius: 1.5rem;
$default-width: var(--notification-width, 30rem);
$default-height: var(--notification-height, 5rem);

.#{bem.$base}.notification {
  align-items: center;
  background-color: var(--x);
  border-radius: $default-border-radius;
  bottom: 0;
  color: var(--on-x);
  display: flex;
  justify-content: space-between;
  margin-bottom: $default-margin;
  margin-right: $default-margin;
  min-height: $default-height;
  overflow: hidden;
  padding: $default-padding calc($default-padding * 2);
  position: fixed;
  right: 0;
  width: $default-width;
  z-index: 9999;

  > .message-container {
    align-items: center;
    display: flex;
    overflow: auto;

    > .message {
      font-size: calc(var(--s0) * 1rem);
      margin-left: calc($default-margin / 2);
    }

    > span {
      font-size: calc(var(--s2) * 1rem);
    }
  }

  .#{bem.$base}.button {
    padding: calc($default-padding / 2);

    &:focus {
      background-color: transparent;
    }
  }
}


