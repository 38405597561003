/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

/*
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 40px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #000000 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : .7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;
*/

$hamburgers-types:(
  squeeze
);
$hamburger-layer-color         : var(--x);
$hamburger-padding-x           : .5em;
$hamburger-padding-y           : .5em;

// Open the following file for the template

@import "hamburgers/_sass/hamburgers/hamburgers";
@import "hamburgers/_sass/hamburgers/types/squeeze";

/*
.#{bem.$base}.hamburger-button{
} */


