/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- Required here  */
/* stylelint-disable max-nesting-depth -- Required here  */
/* stylelint-disable selector-max-universal -- Required here  */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-border-radius: var(--theme-default-border-radius);
$default-padding: var(--gap);
$default-message-max-width: calc(100% - 4 * #{$default-padding});
$default-message-border: 1px solid var(--hard-background-cards);

.#{bem.$base}.conversation {
  background: var(--y);

  > .#{bem.$base}.section {
    display: flex;
    flex-direction: column;

    > .#{bem.$base}.message + .#{bem.$base}.message {
      padding-top: $default-padding;
    }

    > .#{bem.$base}.message.ours + .#{bem.$base}.message.ours,
    > .#{bem.$base}.message.theirs + .#{bem.$base}.message.theirs {
      padding-top: calc($default-padding / 2);
    }

    > .#{bem.$base}.message {
      align-self: flex-start;
      max-width: $default-message-max-width;
      position: relative;

      > .sender {
        margin: 0;
        padding: 0 var(--gap);
      }

      > .message-content {
        background-color: var(--x);
        border: $default-message-border;
        border-radius: $default-border-radius;
        display: flex;
        flex-direction: column;
        padding: 0 $default-padding;

        > p {
          color: var(--on-x);
        }

        > ul {
          padding: 0 var(--gap);
          text-align: left;

          > li {
            color: var(--on-x);
          }
        }

        > * {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        > .attachment {
          align-items: center;
          align-self: flex-end;
          display: flex;
          flex-direction: row;
          gap: calc($default-padding / 2);
        }
      }

      &.#{bem.$base}.theirs {
        align-self: flex-end;

        > .sender {
          text-align: end;
        }
      }
    }
  }
}
