/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-border-radius: var(--theme-default-border-radius);
$default-size: var(--size, 1.2em);
$default-slider-color: var(--slider-color, var(--background-far));
$default-slider-border-color: var(--slider-border-color, var(--ui-lines));
$default-background-color: var(--slider-background-color, var(--interactive));

.#{bem.$base}.toggle-switch {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;

  >input {
    height: 0;
    visibility: hidden;
    width: 0;
  }

  >label {
    background: $default-background-color;
    border-radius: $default-border-radius;
    cursor: pointer;
    display: block;
    height: $default-size;
    position: relative;
    width: calc($default-size * 2);

    &::after {
      background: $default-slider-color;
      border: 1px solid $default-slider-border-color;
      border-radius: $default-border-radius;
      content: "";
      height: $default-size;
      left: 0;
      position: absolute;
      top: 0;
      transition: .3s;
      width: $default-size;
    }
  }

  >input:checked + label::after {
    left: $default-size;
  }
}


