/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-universal -- exception */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-padding: var(--spacing-2) 0;
$default-border-radius: 2.25rem;
$default-border-color: var(--hard-background-cards);
$default-background: var(--background-cards-50);

$default-blur: var(--theme-default-blur);

.#{bem.$base}.meta-card {
  background: $default-background;
  border: 1px solid $default-border-color;
  border-radius: $default-border-radius;
  padding: $default-padding;

  &.#{bem.$modifier-blur} {
    backdrop-filter: $default-blur;
  }

  @include mixins.media($to: $sm-md) {
    // Temporary solution
    margin-left: calc((var(--gap)) * -1);
    margin-right: calc((var(--gap)) * -1);
    padding: 0 var(--gap);

    &.#{bem.$modifier-spaced} {
      padding: var(--gap);

      > * {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
