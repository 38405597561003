/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "sass:math";

$default-background: var(--background-cards);
$default-border: var(--theme-default-border-style) var(--metadata);
/* stylelint-disable-next-line color-named -- needed for rgba function */
$default-box-shadow: 0 10px 20px rgba(black, .2);
$default-padding: math.div(1em, 2);
$default-margin: 2em;

.#{bem.$base}.modal {
  background: $default-background;
  box-shadow: $default-box-shadow;
  display: flex;
  flex-direction: column;
  left: 50%;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  .modal-header {
    background: $default-background;
    border-bottom: $default-border;
    display: flex;
    font-size: calc(var(--theme-heading-font-size-multiplier, 1) * var(--s1) * 1em);
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 2;

    > p {
      margin: 0;
      padding: $default-padding;
    }

    .close-button {
      display: block;
    }
  }
}
