/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";


$animation-duration:var(--animation-duration, 2s);
$stroke-color:var(--x);

.#{bem.$base}.loading-circle {
  height: var(--height-width);
  width: var(--height-width);
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash-anim {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  svg.svg-circle {
    animation: rotate $animation-duration linear infinite;
    margin: auto;
    transform-origin: center center;

    circle {
      animation: dash-anim $animation-duration ease-in-out infinite;
      stroke: $stroke-color;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-width: var(--stroke-width, 8);
    }
  }
}
