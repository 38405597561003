/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- needed here */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-gap: var(--gap);

.#{bem.$base}.case-study-single {
  > .#{bem.$base}.section {
    > .#{bem.$base}.case-study-heading {
      margin-top: calc($default-gap * 3);
    }
  }

  > .spacing {
    padding-bottom: var(--theme-default-section-spacing);

    @include mixins.media($from: $sm-md) {
      padding-bottom: var(--theme-desktop-section-spacing);
    }
  }

  .case-study-content {
    > div {
      > .#{bem.$base}.quote {
        margin: calc($default-gap * 2) 0;
      }

      :first-child {
        margin-top: 0;
      }

      h1,
      h2,
      .h1,
      .h2 {
        color: var(--highlighted);
        font-weight: 400;
      }

      p:not(h1, h2, .h1, .h2),
      li {
        font-size: 1em;
      }

      p,
      li {
        .emphasis {
          font-weight: 700;
        }
      }

      @include mixins.media($from: $sm-md) {
        p:not(h1, h2, .h1, .h2),
        li {
          font-size: calc(var(--s1) * 1em);
        }
      }
    }
  }
}
