/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

// $default-transition-time: var(--default-transition);
$default-transition-time: .3s;

.#{bem.$base}.faq {
  > .#{bem.$base}.item > .question-container {
    align-items: center;
    color: var(--title);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    transition: $default-transition-time;
    width: 100%;

    > span {
      color: var(--interactive-icons);
      margin-left: var(--gap);
      transition: $default-transition-time;

      &.#{bem.$modifier-open} {
        transform: rotate(45deg);
      }
    }

    > .question {
      margin-block-start: 1em;
    }
  }

  > .#{bem.$base}.item > .answer {
    color: var(--paragraph);
    display: none;

    /* stylelint-disable selector-max-universal, selector-max-compound-selectors -- exception */
    > div > *:first-child {
      margin-top: 0;
    }

    &.#{bem.$modifier-open} {
      display: block;
    }
  }
}
