/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-margin: 1em;

.#{bem.$base}.form-input {
  & + & {
    margin-top: $default-margin;
  }
}
