/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-icon:"";
$default-icon-width:2em;
$default-icon-margin:.5em;
$default-icon-font:icons;
$default-icon-margin-top:.1em;//.2em;

.#{bem.$base}.icon-list {
  list-style: none;

  > .#{bem.$element-item} {
    position: relative;

    &::before {
      content: var(--list-icon, #{$default-icon});
      font-family: $default-icon-font;
      left: -($default-icon-width + $default-icon-margin);
      position: absolute;
      text-align: center;
      top: $default-icon-margin-top;
      transition: color .3s;
      width: $default-icon-width;
    }

    &:hover,
    &:focus {
      &::before {
        content: var(--list-icon-hover, var(--list-icon, #{$default-icon}));
      }
    }
  }
}


