/* @pareto-engineering/generator-front 1.1.1-alpha.1 */

@use "@pareto-engineering/bem";

$default-padding: .5rem;

.#{bem.$base}.removable {
  align-items: center;
  background-color: var(--background-inputs);
  border: var(--theme-default-border-style) var(--main2);
  border-radius: $default-padding;
  display: flex;
  justify-content: space-between;
  padding: $default-padding;

  > :first-child {
    flex-grow: 1;
  }

  .close-button {
    background-color: transparent;
    border: none;
    color: var(--metadata);
    cursor: pointer;
    margin-left: var(--gap);

    &:hover {
      color: var(--hard-metadata);
    }
  }
}


