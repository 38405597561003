/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.section-hero {
  margin-top: calc(var(--gap) * 2);

  > div {
    > .actions {
      display: flex;
      gap: var(--gap);

      >.#{bem.$base}.button {
        align-items: center;
      }

      >.#{bem.$base}.button.secondary-action {
        border: 2px solid var(--x);
        color: var(--paragraph);

        &:hover:not(:disabled) {
          background: var(--x);
          color: var(--on-x);
        }
      }
    }

    .#{bem.$base} {

      @include mixins.media($to:$sm-md) {
        p {
          font-size: calc(var(--s0) * 1em);
        }
      }
    }
  }
}
