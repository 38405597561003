/* @pareto-engineering/generator-front 1.1.1-alpha.2 */

@use "@pareto-engineering/bem";

$default-padding: 1rem;
$default-margin: 1rem;

.#{bem.$base}.file-upload {
  display: flex;
  flex-direction: column;
  gap: var(--gap);


  > p {
    color: var(--y);
    margin: 0;
  }

  > .#{bem.$base}.description {
    height: auto;
  }

  > .#{bem.$base}.form-label {
    align-items: center;
    border: var(--theme-default-border-style) var(--ui-lines);
    border-radius: calc(var(--theme-default-border-radius) / 2);
    color: var(--heading);
    cursor: pointer;
    display: flex;
    gap: calc(var(--gap) / 2);
    padding: $default-padding;
    width: fit-content;

    &:has(>input:disabled) {
      --icon-color: var(--hard-disabled);
      color: var(--hard-disabled);
      cursor: not-allowed;
    }

    &:has(>input:not(:disabled)) {
      &:hover,
      &:active {
        border: var(--theme-hover-input-border);
      }
    }

    > .ai-icon {
      color: var(--icon-color, var(--interactive));
      font-size: calc(var(--s2) * 1rem);
    }

    > input[type="file"] {
      display: none;
    }
  }

  > .attached-files {
    display: flex;
    gap: var(--gap);

    > p {
      margin: 0;
    }

    > .files {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: var(--gap);
      width: 100%;
    }
  }
}
