/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-universal -- exception */

@use "@pareto-engineering/bem";

$default-border-radius: var(--theme-default-border-radius);
$default-border-color: var(--hard-background-cards);
$default-background: var(--background-cards-80);
$default-padding: 1.5em;

.#{bem.$base}.card {
  background: $default-background;
  border: 1px solid $default-border-color;
  border-radius: $default-border-radius;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  > .progress-bar {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .#{bem.$base}.section:nth-last-child(2) {
    flex-grow: 1;
  }

  > .#{bem.$base}.section {
    padding: $default-padding;

    &.#{bem.$modifier-image} {
      padding: 0;

      img {
        border-radius: $default-border-radius;
        width: 100%;
      }
    }

    > * {
      &:first-child {
        margin-block-start: 0;
      }

      &:last-child {
        margin-block-end: 0;
      }
    }
  }

  > .#{bem.$base}.section + .#{bem.$base}.section {
    padding-top: 0;
  }
}
