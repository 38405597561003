/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable selector-max-compound-selectors -- exception */

@use "@pareto-engineering/bem";

$default-dot-size: .5em;
$default-margin: 1em;
$default-padding: 1em;

.#{bem.$base}.content-tree {
  > .#{bem.$base}.tree {
    position: sticky;
    top: var(--theme-default-header-height-offset);

    ul {
      list-style: none;
      padding: 0;

      &:first-child > li > p {
        margin-top: 0;
      }

      &:last-child > li > p {
        margin-bottom: 0;
      }

      > li > p > a {
        color: var(--paragraph);
        text-decoration: none;
      }
    }

    .#{bem.$modifier-active} {
      font-weight: 700;

      &::before {
        background: var(--x);
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: $default-dot-size;
        margin-right: $default-margin;
        text-align: center;
        width: $default-dot-size;
      }
    }

    li:not(:last-child) {
      margin-bottom: $default-margin;

      ul {
        padding-left: $default-padding;
      }
    }
  }
}
