/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

$default-color: var(--black);
$default-height:var(--height);

.#{bem.$base}.dot-info {
  background: var(--x);
  border-radius: 50%;
  color: $default-color;

  height: $default-height;
  width: $default-height;

  &.empty {
    background: transparent;
    border: 1px solid var(--x);
  }
}
