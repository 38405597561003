/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.section-cta {
  @include mixins.media($from: $sm-md) {
    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}
