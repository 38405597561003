/* stylelint-disable selector-max-id -- to select the hamburger menu */

/* stylelint-disable selector-max-universal -- needed in the menu */

/* stylelint-disable max-nesting-depth -- needed */
/* stylelint-disable selector-max-compound-selectors -- needed */

/* @pareto-engineering/generator-front 1.1.1-alpha.1 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;
$input-id:hamburger_input;
$default-line-height:.2rem;
$default-navbar-height: $default-line-height * 5;
$default-border-radius: var(--theme-default-border-radius);
$default-blur: var(--theme-default-blur);
$default-hamburger-color:heading;
$default-logo-color:heading;
$default-border-color:var(--hard-background-cards);
$default-padding: 1em;
$default-margin: 1em;
$var: var(--content);

.#{bem.$base}.navigation-bar {
  display: block;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  @include mixins.media($to:$md-lg) {
    &.nav-open {
      height: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  nav {
    align-content: center;
    align-items: center;
    backdrop-filter: var(--theme-default-blur);
    background-color: var(--background-cards);
    border-bottom: 1px solid $default-border-color;
    padding: 0 1em;
    transition: all .3s;

    a {
      text-decoration: none;
    }

    // Mobile & Tablet
    @include mixins.media($to:$md-lg) {
      display: flex;
      flex-direction: column;
      gap: 0;
      min-height: auto;
      padding: $default-padding;
      position: relative;
      width: 100%;

      > .cta-desktop {
        display: none;
      }

      > .logo-wrapper {
        margin-right: auto;

        .logo {
          > .name {
            display: none;
          }
        }
      }

      ul.content {
        font-size: calc(var(--s1) * 1rem);
        padding-right: $default-padding;
        padding-top: $default-padding;

        > .cta-mobile {
          margin-top: $default-margin;
        }

        > li.item {
          flex-direction: column;
          justify-content: flex-start;
          padding: var(--gap) 0;

          > .sub-items-wrapper {
            /* stylelint-disable-next-line declaration-no-important -- required */
            display: var(--display) !important;
            flex-direction: column;
            padding-left: 0;

            > .sub-item {
              a {
                color: var(--paragraph);
              }
            }
          }

          .#{bem.$base}.button {
            >.underlined {
              justify-content: space-between;
              width: 100%;

              > .icon::before {
                content: var(--icon);
              }
            }
          }

          &:first-child {
            margin-top: 1rem;
          }
        }
      }
    }

    // Desktop
    @include mixins.media($from:$md-lg) {
      .logo-wrapper {
        display: grid;
      }

      ul.content {
        > .cta-mobile {
          display: none;
        }

        > li.item {
          align-items: center;
          font-size: calc(var(--s1) * 1rem);
          justify-content: center;

          > .sub-items-wrapper {
            border: 1px solid $default-border-color;
            border-radius: var(--theme-default-border-radius);
            grid-auto-flow: column;
            grid-template-rows: repeat(var(--rows), 1fr);
            min-width: 10rem;
            padding: calc($default-padding * 1.5);
            position: absolute;
            top: 3.5rem;
            z-index: 1;

            > .sub-item {
              a {
                color: var(--heading);
              }
            }
          }

          &:hover {
            > .sub-items-wrapper {
              display: grid;
            }
          }

          .#{bem.$base}.button {
            >.underlined {
              > .icon::before {
                content: "V";
              }
            }
          }
        }
      }
    }

    .logo {
      align-items: center;
      color: var(--#{$default-logo-color});
      display: flex;
      gap: 1em;
      height: $default-navbar-height;
      justify-content: left;
      margin: 0;
      width: 100%;

      strong {
        font-size: calc(var(--s1) * 1em);
      }
    }

    ul.content {
      align-items: center;
      display: flex;
      list-style: none;
      margin: 0;
      padding-left: 0;
      z-index: 1;

      > li.item {
        cursor: pointer;
        display: flex;
        height: 100%;
        margin: 0;
        position: relative;
        transition: all .3s;

        .#{bem.$base}.button {
          &:hover {
            color: var(--heading);
          }

          >.underlined {
            align-items: center;
            display: flex;
            gap: calc(var(--gap) / 2);
            text-decoration: none;
          }
        }

        > * {
          color: var(--heading);
          display: flex;
        }

        > .sub-items-wrapper {
          backdrop-filter: var(--theme-default-blur);
          background-color: var(--background-cards);
          color: var(--heading);
          display: none;
          font-size: calc(var(--s0) * 1rem);
          gap: var(--gap);
          list-style: none;

          > .sub-item {
            a {
              display: inline-block;
              padding: calc(var(--gap) / 4);
              text-decoration: none;
              transition: all .3s;
              width: 100%;
            }

            &:hover {
              a {
                color: var(--highlighted);
              }
            }

            &:active {
              a {
                color: var(--hard-highlighted);
              }
            }
          }
        }
      }
    }

    > .cta-wrapper {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }

  label[for="#{$input-id}"] {
    cursor: pointer;
    position: absolute;
    right: calc(#{$default-padding} * 2);
    top: calc(#{$default-navbar-height} * .5 + #{$default-padding});
    transform: translateY(-50%);
    z-index: 3;

    span {
      background: var(--#{$default-hamburger-color});
      border-radius: 3px;
      display: block;
      height: $default-line-height;
      position: relative;

      transform-origin: $default-line-height 0;

      transition: transform .5s cubic-bezier(.77, .2, .05, 1),
        background .5s cubic-bezier(.77, .2, .05, 1),
        opacity .55s ease;
      width: $default-line-height*7.2;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: $default-line-height;
      }

      &:first-child {
        transform-origin: $default-line-height * .5 $default-line-height * .5;
      }

      &:nth-child(3) {
        transform-origin: $default-line-height calc(100% - #{$default-line-height * .5});
      }
    }
    @include mixins.media($from:$md-lg) {
      display: none;
    }
  }

  input##{$input-id} {
    display: none;

    &:checked + label[for="#{$input-id}"] {
      span {
        &:first-child {
          opacity: 1;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: rotate(0deg) scale(.2, .2);
        }

        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }

    // Mobile & Tablet
    @include mixins.media($to:$md-lg) {
      &:checked ~ nav {
        min-height: 100%;
      }

      ~ nav .content {
        display: none;
        justify-content: space-between;
        left: -100vw;
        position: relative;
        transition: all .3s;
        width: 100%;
      }

      &:checked ~ nav .content {
        display: grid;
        left: 0;
      }
    }
  }

  // Desktop
  @include mixins.media($from:$md-lg) {
    display: grid;
    height: auto;
    overflow: unset;

    nav {
      border: 1px solid $default-border-color;

      ul.content {
        background: transparent;
        gap: calc(var(--gap) * 3);
        height: 100%;
        justify-content: center;

        > li.item {
          padding: 0;
          padding-block: calc(#{$default-padding} / 1.2);
          white-space: nowrap;
        }
      }
    }
  }
}
