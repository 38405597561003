/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-gap: calc(var(--gap) / 2);
$default-padding: .55em .75em;
$default-input-border-radius: var(--theme-default-input-border-radius);
$default-border: var(--theme-default-input-border);
$focus-border: var(--theme-focus-input-border);
$active-background: var(--hard-background-inputs);
$default-background: var(--background-inputs);
$default-icon-color: var(--on-background-inputs);
$disabled-background: var(--background-inputs-30);
$default-color-menu-padding: .5em .25em;
$default-check-mark-dimensions: 1rem;

.#{bem.$base}.editor-input {
  display: flex;
  flex-direction: column;

  > .#{bem.$base}.form-label {
    margin-bottom: var(--gap);
  }

  &:not(.disabled) {
    &:hover,
    &:focus,
    &:active {
      > .textarea {
        border: $focus-border;
      }
    }

    > .content-editable {
      border-bottom-left-radius: $default-input-border-radius;
      border-bottom-right-radius: $default-input-border-radius;
    }
  }

  &.disabled {
    > .textarea {
      background: $disabled-background;
      color: var(--metadata);
      cursor: not-allowed;
    }

    > .content-editable {
      border-radius: $default-input-border-radius;
    }
  }

  .color-menu-button {
    &:hover {
      > .#{bem.$base}.popover {
        display: block;
      }
    }

    > .#{bem.$base}.popover {
      padding: $default-color-menu-padding;

      .color-menu {
        display: flex;
        flex-wrap: wrap;
        gap: calc($default-gap / 2);
        justify-content: center;
        max-width: 10em;
        min-width: 5em;
      }

      .color-option:hover {
        opacity: .5;
      }
    }
  }

  > .content-editable {
    background: $default-background;
    border: $default-border;
    color: var(--y);
    height: var(--rows);
    outline: none;
    overflow: auto;
    padding: $default-padding;
    resize: var(--resize);
    width: 100%;

    .lexical-list-item-checked,
    .lexical-list-item-unchecked {
      list-style-type: none;
      margin-inline: calc(var(--gap) / 2);
      outline: none;
      padding-inline: calc(var(--theme-default-padding) * 1.5);
      position: relative;
    }

    .lexical-list-item-checked {
      text-decoration: line-through;
    }

    .lexical-list-item-unchecked::before,
    .lexical-list-item-checked::before {
      background-size: cover;
      content: "";
      cursor: pointer;
      display: block;
      height: $default-check-mark-dimensions;
      left: 0;
      position: absolute;
      top: 2px;
      width: $default-check-mark-dimensions;
    }

    .lexical-list-item-unchecked[dir="rtl"]::before,
    .lexical-list-item-checked[dir="rtl"]::before {
      left: auto;
      right: 0;
    }

    .lexical-list-item-unchecked:focus::before,
    .lexical-list-item-checked:focus::before {
      border-radius: 2px;
      box-shadow: 0 0 0 2px var(--light-blue);
    }

    .lexical-list-item-unchecked::before {
      border: var(--theme-default-border-style) var(--ui-icons);
      border-radius: 2px;
    }

    .lexical-list-item-checked::before {
      background-color: var(--blue);
      background-repeat: no-repeat;
      border: var(--theme-default-border-style) var(--soft-blue);
      border-radius: 2px;
    }

    .lexical-list-item-checked::after {
      border-color: var(--background-far);
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: "";
      cursor: pointer;
      display: block;
      height: 6px;
      left: 7px;
      position: absolute;
      right: 7px;
      top: 6px;
      transform: rotate(45deg);
      width: 3px;
    }

    .lexical-code {
      background-color: var(--hard-background-cards);
      display: block;
      font-size: calc(var(--s-1) * 1rem);
      margin: 0;
      margin-bottom: calc(var(--gap) / 2);
      margin-top: calc(var(--gap) / 2);
      overflow-x: auto;
      padding-block: calc(var(--theme-default-padding) / 2);
      padding-bottom: calc(var(--theme-default-padding) / 2);
      padding-left: calc(var(--theme-default-padding) * 2);
      position: relative;
      tab-size: 2;
    }

    .lexical-code::before {
      background-color: var(--hard-background-cards);
      border-right: var(--theme-default-border-style) var(--ui-icons);
      color: var(--pagraph);
      content: attr(data-gutter);
      left: 0;
      padding: calc(var(--theme-default-padding) / 2);
      position: absolute;
      text-align: right;
      top: 0;
      white-space: pre-wrap;
    }

    li:has(ol, ul) {
      list-style-type: none;
    }

    a {
      cursor: pointer;
    }

    blockquote {
      border-left: 4px solid var(--ui-icons);
      font-size: calc(var(--s0) * 1rem);
      margin: 0;
      margin-left: var(--gap);
      padding-left: calc(var(--theme-default-padding) / 2);
    }

    p,
    span,
    strong,
    em,
    li {
      &.underlined {
        text-decoration: underline;

        &.strikethrough {
          text-decoration: underline line-through;
        }
      }

      &.strikethrough {
        text-decoration: line-through;
      }

      &.italic {
        font-style: italic;
      }
    }

    :first-child {
      margin-top: 0;
    }

    &::placeholder {
      color: var(--metadata);
    }
  }


  > .tree-view-output {
    background: $default-background;
    border: $default-border;
    border-radius: $default-input-border-radius;
    max-height: 20em;
    overflow: auto;
    padding: $default-padding;
  }
}
