/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-dimensions: 2em;
$mobile-dimensions: 2.75em;

.#{bem.$base}.social-media-button {
  appearance: none;
  border: 0;
  cursor: pointer;
  font-family: "icons", sans-serif;
  transition: all .3s;

  &:focus {
    background: var(--soft-x);
  }

  &.#{bem.$modifier-clear} {
    background: transparent;
    color: var(--y, var(--heading));

    &:hover {
      color: var(--x);
    }

    &:focus {
      color: var(--soft-x);
    }
  }
}


