/* @pareto-engineering/generator-front 1.1.1-alpha.1 */

@use "@pareto-engineering/bem";

.#{bem.$base}.blur-overlay {
  background: inherit;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  &.#{bem.$modifier-active} {
    animation: blurAnimation .3s ease-in-out;
    backdrop-filter: blur(5px);
    background: var(--blur-overlay-background, inherit);
    height: 100%;
    z-index: var(--blur-overlay-z-index, 3);
  }
}

@keyframes blurAnimation {
  0% {
    transform: translateX(-100%);
  }

  1% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}
