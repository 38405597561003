/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.steps {
  > .steps-step {
    &:not(:last-child) {
      margin-bottom: var(--gap);
    }

    > svg {
      @include mixins.media($from: $sm-md) {
        justify-self: center;
      }
    }
  }
}
