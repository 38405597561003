/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-gap: var(--gap);
$default-max-image-height: 20em;
$default-code-font-size: .8em;

.#{bem.$base}.blog-post {
  max-width: 1920px;

  > .title > h1 {
    margin: 0;
  }

  > .content-tree {
    display: none;
    margin-top: $default-gap;
  }

  > .illustration-container {
    overflow: hidden;
    padding-top: 50%;
    position: relative;
    width: 100%;

    > img {
      border-radius: var(--theme-default-border-radius);
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  > .post-info {
    color: var(--soft-paragraph);
    display: flex;
    flex-direction: column;
    gap: $default-gap;

    > .post-sub {
      align-items: center;
      display: flex;
      gap: $default-gap;
    }

    > .buttons {
      align-items: center;
      display: flex;
      gap: $default-gap;

      > .#{bem.$base}.label {
        color: var(--soft-paragraph);
      }
    }
  }

  > .article-body {
    :first-child {
      margin-top: $default-gap;
    }

    ul {
      list-style: square;
    }

    img {
      width: 100%;
    }

    a {
      color: var(--interactive);
    }
  }

  pre code {
    background-color: var(--background-near);
    border-radius: calc(var(--theme-default-border-radius) / 2);
    color: var(--paragraph);
    display: block;
    font-size: $default-code-font-size;
    overflow-x: auto;
    padding: $default-gap;
  }

  @include mixins.media($from: $sm-md) {
    > .content-tree {
      display: block;
    }

    > .post-info {
      align-items: center;
      flex-direction: row;

      > .buttons {
        gap: calc($default-gap * 2);
      }
    }
  }
}
