/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

.#{bem.$base}.animated-gradient {
  --gradient-color-1: var(--hard-background-mesh);
  --gradient-color-2: var(--soft-background-mesh);
  --gradient-color-3: var(--background-mesh);
  --gradient-color-4: var(--soft-background-mesh);
  background-color: var(--background-mesh);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
