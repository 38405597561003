// stylelint-disable selector-max-id -- exception
// stylelint-disable selector-max-specificity -- exception
// stylelint-disable selector-max-compound-selectors -- exception

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$breakpoint: 480px;
$min-width: 2560px;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes expand {
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    height: 100%;
    opacity: 1;
  }
}

#page-home {
  #testimonials {
    &.fade-in {
      animation: fadeIn 1s ease-in-out;
    }
  }

  #spade-interaction {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    width: min-content;
    z-index: 2;

    > .spade-icon {
      &:hover,
      &:focus {
        color: var(--on-heading);

        + .#{bem.$base}.popover {
          display: block;
        }
      }
    }

    > .#{bem.$base}.popover {
      background: transparent;
      border: none;
      color: var(--metadata);
      min-width: 14em;

      > .#{bem.$base}.card {
        background: var(--background-cards);

        > .#{bem.$base}.section {
          padding: .5em;

          // stylelint-disable-next-line max-nesting-depth -- exception
          > ul {
            padding: 0 0 0 var(--gap);
            text-align: left;

            // stylelint-disable-next-line max-nesting-depth -- exception
            > li:not(:last-child) {
              padding-bottom: var(--gap);
            }
          }
        }
      }
    }
  }
}

#ai-home {
  .#{bem.$base}.use-case-card {
    &.hidden {
      display: none;
    }

    &.revealed {
      animation: expand .5s ease-in-out;
      overflow: hidden;
    }
  }
}

#page-single-blog {
  .article-body {
    [id]::before {
      content: "";
      display: block;
      height: var(--theme-default-header-height-offset);
      margin-top: calc(var(--theme-default-header-height-offset) * -1);
      visibility: hidden;
    }
  }

  > .blog-meta-props {
    margin-bottom: var(--theme-default-section-spacing);
  }

  > .post-content {
    iframe {
      max-width: 100%;
    }
    @media only screen and (min-width: $min-width) {
      display: flex;
      justify-content: center;
    }
  }
}
