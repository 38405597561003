@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-padding: 1rem;
$default-margin: 1rem;
$default-width: var(--notification-stack-width, 30rem);

.#{bem.$base}.notification-stack {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  width: $default-width;
  z-index: 9999;

  > .#{bem.$base}.notification {
    position: relative;
    width: 100%;
  }
}
