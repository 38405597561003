/* @pareto-engineering/generator-front 1.0.12 */
/* stylelint-disable max-nesting-depth -- needed here */
/* stylelint-disable selector-max-compound-selectors -- needed here */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

$default-input-padding: .55em .75em;
$default-gap: var(--gap);
$default-loading-circle-displacement: .5em;
$default-input-border-radius: var(--theme-default-input-border-radius);
$default-border: var(--theme-default-input-border);
$hover-border: var(--theme-hover-input-border);
$focus-border: var(--theme-focus-input-border);
$default-background: var(--background-inputs);
$on-default-background: var(--paragraph);
$disabled-background: var(--background-inputs-30);
$on-disabled-background: var(--on-background-inputs-30);

.#{bem.$base}.combobox,
.#{bem.$base}.multiple-combobox {
  display: flex;
  flex-direction: column;
  outline: none;
  position: relative;

  > .#{bem.$base}.form-label {
    margin-bottom: var(--gap);
  }


  .#{bem.$base}.popover {
    border: $default-border;
    border-radius: $default-input-border-radius;
    width: 100%;

    >.menu {
      list-style: none;
      margin: 0;
      outline: 0;
      padding: 0;

      >.item {
        border-radius: $default-input-border-radius;
        padding: $default-input-padding;

        > p {
          margin: 0;
        }

        &.#{bem.$modifier-active} {
          background-color: var(--y);

          > p {
            color: var(--on-y);
          }
        }
      }
    }
  }


  > .input-container {
    position: relative;

    >.#{bem.$base}.loading-circle,
    >.#{bem.$base}.button {
      position: absolute;
      right: $default-loading-circle-displacement;
    }

    >.#{bem.$base}.loading-circle {
      top: calc($default-loading-circle-displacement * 1.5);
    }

    >.#{bem.$base}.button {
      top: $default-loading-circle-displacement;
    }


    > .input {
      background: $default-background;
      border: $default-border;
      border-radius: calc(var(--theme-default-border-radius) / 2);
      color: $on-default-background;
      outline: none;
      padding: $default-input-padding;
      width: 100%;

      &::placeholder {
        color: var(--metadata);
      }

      &:disabled {
        background-color: $disabled-background;
        color: $on-disabled-background;
      }

      &:not(:disabled) {
        &:hover,
        &:active {
          border: $hover-border;
        }

        &:focus {
          border: $focus-border;
        }
      }
    }
  }
}


.#{bem.$base}.multiple-combobox {
  >.selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: calc($default-gap / 2);
    margin-bottom: calc($default-gap / 2);

    >.item {
      >.#{bem.$base}.button {
        align-items: center;
        display: flex;
        gap: calc($default-gap / 2);
      }

      .close {
        font-size: calc(var(--s-3) * 1em);
      }
    }
  }
}
