/* @pareto-engineering/generator-front 1.0.12 */

@use "@pareto-engineering/bem";

.#{bem.$base}.spinner {
  display: flex;
  justify-content: center;

  .loader {
    animation: spin 2s linear infinite;
    border: .5em solid var(--on-x);
    border-radius: 50%;
    border-top: .5em solid var(--x);
    height: 4em;
    width: 4em;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}


