/* @pareto-engineering/generator-front 1.0.0 */
/* stylelint-disable selector-max-universal -- exception */

@use "@pareto-engineering/bem";
@use "@pareto-engineering/styles/src/mixins";
@use "@pareto-engineering/styles/src/globals" as *;

.#{bem.$base}.page {
  position: relative;

  > .background {
    position: fixed;
    z-index: -1;
  }

  > .#{bem.$base}.section {
    position: relative;

    &.padded {
      padding-left: var(--gap);
      padding-right: var(--gap);
    }

    > *:not(:last-child) {
      z-index: 1;
    }

    &.#{bem.$modifier-spaced} {
      padding-bottom: var(--theme-default-section-spacing);
      padding-top: var(--theme-default-section-spacing);

      @include mixins.media($from: $sm-md) {
        padding-bottom: var(--theme-desktop-section-spacing);
        padding-top: var(--theme-desktop-section-spacing);
      }
    }
  }
}
